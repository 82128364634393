<template>
  <div class="trainings-list">
    <h1 class="title text-h4 text-md-start text-center">
      {{ $t('menu.trainings') }}
    </h1>

    <v-text-field
      :label="$t('globals.search')"
      dense
      outlined
      hide-details="auto"
      prepend-inner-icon="mdi-magnify"
      dark
      class="search mt-4 mx-auto mx-md-0"
      @input="searchFilter"
    />

    <ul class="training-card-list">
      <li v-for="(info, idx) in listTraining" :key="info.title">
        <TrainingsCard
          :id="info.id"
          :img="info.coverImage"
          :title="info.name"
          :avgTime="'4h20'"
          :trainingsProgress="{
            progress: '100%',
            notes: '60%',
          }"
          :description="''"
          :class="{ 'mt-4': idx != 0 }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import TrainingsCard from '@/components/trainings/trainings-card/TrainingsCard.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  CLEAR_ALL_TRAINING,
  DELETE_TRAINING,
  GET_ALL_TRAININGS_ADMIN,
  GET_TRAINING,
  TRAINING_ADMIN_MODULE,
} from '@/stores/umanize-admin/actions/trainings/trainings.actions';

export default {
  name: 'TrainingsList',
  components: {
    TrainingsCard,
  },
  data() {
    return {
      listTraining: [],
    };
  },
  computed: {
    ...mapGetters(TRAINING_ADMIN_MODULE, [
      'allTrainingState',
      'trainingStateAreLoading',
      'allTrainingData',
      'trainingData',
    ]),
  },
  methods: {
    ...mapActions(TRAINING_ADMIN_MODULE, [
      GET_ALL_TRAININGS_ADMIN,
      DELETE_TRAINING,
      CLEAR_ALL_TRAINING,
      GET_TRAINING,
    ]),
    searchFilter(item) {
      if (item?.length) {
        this.listTraining = this.allTrainingData.filter((e) =>
          e.name.toLowerCase().includes(item.toLowerCase().trim()),
        );
      } else this.listTraining = this.allTrainingData;
    },
  },
  async mounted() {
    await this[GET_ALL_TRAININGS_ADMIN]({ eventId: this.$route.params.eventId });
    this.listTraining = this.allTrainingData;
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.trainings-list {
  min-height: calc(100vh - 4.3rem);
  width: 100vw;
  background-image: url('~@assets/backgrounds/training-detail1.png');
  background-size: 100% 100%;
  padding-inline: 10%;
  padding-block: 50px;
}

@include breakpoint(small) {
  .trainings-list {
    margin-left: -10vw;
    padding-inline: 15%;
  }
}

.title {
  color: white;
}

.search {
  width: 50%;
}

.training-card-list {
  list-style-type: none;
  margin-top: 20px;
  padding-left: 0;
}

@include breakpoint(large) {
  .training-card-list {
    * {
      max-height: 94px;
    }
  }
  .search {
    width: 160px;
  }
}
</style>
